<template>
  <b-card :class="isBordered ? 'border-light-secondary' : ''">
    <div class="d-flex">
      <div class="mr-1">
        <b-avatar rounded="lg" variant="light-warning">
          <i class="card-icon ti ti-brand-hipchat " />
        </b-avatar>
      </div>
      <div class="d-flex flex-column ml-1 w-100">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column ">
            <span class="text-muted font-weight-bolder ">Forum</span>
            <h6 class="font-weight-bolder small-gap-bottom">
              {{ forum.title }}
            </h6>
          </div>
          <div>
            <div class="d-flex justify-content-end">
              <b-button
                v-if="isContentCreator"
                size="sm"
                class="d-flex align-items-center small-gap-right"
                :to="{
                  name: checkAuthorizeRole('classroom-detail-forum-edit'),
                  params: {
                    id: classroomId,
                    contentId: contentId,
                    forumId: forum.id,
                  },
                }"
                variant="warning"
              >
                <i class="regular-icon align-middle ti ti-pencil "></i>
              </b-button>
              <b-button
                v-if="isContentCreator"
                size="sm"
                class="d-flex align-items-center small-gap"
                @click="deleteForum(forum.id)"
                variant="danger"
              >
                <i class="regular-icon align-middle ti ti-trash "></i>
              </b-button>
            </div>
            <b-button
              v-if="!isContentCreator"
              :to="{
                name: checkAuthorizeRole('classroom-detail-forum-detail'),
                params: {
                  id: classroomId,
                  contentId: contentId,
                  forumId: forum.id,
                },
              }"
              class="d-flex align-items-center"
              variant="outline-primary"
            >
              Lihat Forum
              <i
                class="regular-icon align-middle ti ti-chevron-right small-gap-right"
              />
            </b-button>
          </div>
        </div>

        <span class="text-muted">
          {{ localizeISODate(forum.created_at, 'ID-id') }}
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>
import client from '@/libs/http/axios-config'
import { localizeISODate } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'

import { BCard, BButton, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BAvatar,
  },
  props: {
    forum: {
      type: Object,
      required: true,
    },
    isBordered: {
      type: Boolean,
    },
    isContentCreator: {
      type: Boolean,
    },
  },
  data() {
    return {
      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
    }
  },
  methods: {
    localizeISODate,
    checkAuthorizeRole,
    // Delete
    deleteForum(forumId) {
      this.$swal({
        title: 'Anda Yakin?',
        text: 'Data yang dihapus tidak dapat dikembalikan',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteForumRequest(forumId)
        }
      })
    },

    deleteForumRequest(forumId) {
      client
        .delete(
          `/teachers/classrooms/${this.classroomId}/class-contents/${this.contentId}/forums/${forumId}`
        )
        .then((response) => {
          this.$swal({
            icon: 'success',
            title: 'Berhasil!',
            text: 'Tugas berhasil dihapus',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.$router.go()
          })
        })
        .catch((error) => {
          this.$swal({
            icon: 'error',
            title: 'Gagal',
            text: 'Tugas gagal dihapus.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.small-img {
  width: 200px;
}
</style>
